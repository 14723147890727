import * as React from 'react';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Document } from '@contentful/rich-text-types';
import CategoryLayout from '../components/CategoryLayout';
import { Disclosure } from '../models/Disclosure';
import { useDisclosure } from '../operations/queries';
import options from '../utils/getRichTextOptions';

export default function DisclosurePage(): JSX.Element {
  const disclosure: Disclosure = useDisclosure();
  return (
    <CategoryLayout title="Disclosure">
      {disclosure.texto && renderRichText(disclosure.texto, options)}
    </CategoryLayout>
  );
}
